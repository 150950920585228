<template>
  <div>
    <banner title="Program Schedule" />
    <v-container fluid>
      <p class="text-center pa-0">All times are in PST</p>
      <v-row class="mt-4 mb-4 justify-center align-center">
        <v-col lg="8">
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              :events="events"
              :type="calendarType"
              :weekdays="weekday"
              :start="dailyStartDay"
              :end="dailyEndDay"
              :event-color="getEventColor"
            >
              <template v-slot:day-body="{ day }">
                <div
                  class="v-current-time"
                  :class="{ first: day === 1 }"
                  :style="{ top: nowY }"
                ></div>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";

export default {
  components: { Banner },
  data: () => ({
    weekday: [1, 2, 3, 4, 5],
    ready: false,
  }),
  metaInfo() {
    return {
      title: "Schedule",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "GraceWay Radio Schedule with Listening Times for Featured Speakers",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "GraceWay Radio Schedule with Listening Times for Featured Speakers",
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Schedule" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/program-schedule",
        },
      ],
    };
  },
  computed: {
    events() {
      return [
        {
          name: "Chuck Smith",
          start: this.setEventDateAndTime("monday", "18:00"),
          end: this.setEventDateAndTime("monday", "18:30"),
          color: "indigo",
        },
        {
          name: "Chuck Smith",
          start: this.setEventDateAndTime("tuesday", "18:00"),
          end: this.setEventDateAndTime("tuesday", "18:30"),
          color: "indigo",
        },
        {
          name: "Chuck Smith",
          start: this.setEventDateAndTime("wednesday", "18:00"),
          end: this.setEventDateAndTime("wednesday", "18:30"),
          color: "indigo",
        },
        {
          name: "Chuck Smith",
          start: this.setEventDateAndTime("thursday", "18:00"),
          end: this.setEventDateAndTime("thursday", "18:30"),
          color: "indigo",
        },
        {
          name: "Chuck Smith",
          start: this.setEventDateAndTime("friday", "18:00"),
          end: this.setEventDateAndTime("friday", "18:30"),
          color: "indigo",
        },
        {
          name: "Alistair Begg",
          start: this.setEventDateAndTime("monday", "13:00"),
          end: this.setEventDateAndTime("monday", "13:30"),
          color: "red",
        },
        {
          name: "Alistair Begg",
          start: this.setEventDateAndTime("tuesday", "13:00"),
          end: this.setEventDateAndTime("tuesday", "13:30"),
          color: "red",
        },
        {
          name: "Alistair Begg",
          start: this.setEventDateAndTime("wednesday", "13:00"),
          end: this.setEventDateAndTime("wednesday", "13:30"),
          color: "red",
        },
        {
          name: "Alistair Begg",
          start: this.setEventDateAndTime("thursday", "13:00"),
          end: this.setEventDateAndTime("thursday", "13:30"),
          color: "red",
        },
        {
          name: "Alistair Begg",
          start: this.setEventDateAndTime("friday", "13:00"),
          end: this.setEventDateAndTime("friday", "13:30"),
          color: "red",
        },
        {
          name: "David Pawson",
          start: this.setEventDateAndTime("monday", "10:00"),
          end: this.setEventDateAndTime("monday", "11:00"),
          color: "green",
        },
        {
          name: "David Pawson",
          start: this.setEventDateAndTime("tuesday", "10:00"),
          end: this.setEventDateAndTime("tuesday", "11:00"),
          color: "green",
        },
        {
          name: "David Pawson",
          start: this.setEventDateAndTime("wednesday", "10:00"),
          end: this.setEventDateAndTime("wednesday", "11:00"),
          color: "green",
        },
        {
          name: "David Pawson",
          start: this.setEventDateAndTime("thursday", "10:00"),
          end: this.setEventDateAndTime("thursday", "11:00"),
          color: "green",
        },
        {
          name: "David Pawson",
          start: this.setEventDateAndTime("friday", "10:00"),
          end: this.setEventDateAndTime("friday", "11:00"),
          color: "green",
        },
        {
          name: "John Casteel",
          start: this.setEventDateAndTime("monday", "06:30"),
          end: this.setEventDateAndTime("monday", "07:30"),
          color: "blue",
        },
        {
          name: "John Casteel",
          start: this.setEventDateAndTime("tuesday", "06:30"),
          end: this.setEventDateAndTime("tuesday", "07:30"),
          color: "blue",
        },
        {
          name: "John Casteel",
          start: this.setEventDateAndTime("wednesday", "06:30"),
          end: this.setEventDateAndTime("wednesday", "07:30"),
          color: "blue",
        },
        {
          name: "John Casteel",
          start: this.setEventDateAndTime("thursday", "06:30"),
          end: this.setEventDateAndTime("thursday", "07:30"),
          color: "blue",
        },
        {
          name: "John Casteel",
          start: this.setEventDateAndTime("friday", "06:30"),
          end: this.setEventDateAndTime("friday", "07:30"),
          color: "blue",
        },
        {
          name: "Paul Washer",
          start: this.setEventDateAndTime("monday", "21:00"),
          end: this.setEventDateAndTime("monday", "22:00"),
          color: "purple",
        },
        {
          name: "Paul Washer",
          start: this.setEventDateAndTime("tuesday", "21:00"),
          end: this.setEventDateAndTime("tuesday", "22:00"),
          color: "purple",
        },
        {
          name: "Paul Washer",
          start: this.setEventDateAndTime("wednesday", "21:00"),
          end: this.setEventDateAndTime("wednesday", "22:00"),
          color: "purple",
        },
        {
          name: "Paul Washer",
          start: this.setEventDateAndTime("thursday", "21:00"),
          end: this.setEventDateAndTime("thursday", "22:00"),
          color: "purple",
        },
        {
          name: "Paul Washer",
          start: this.setEventDateAndTime("friday", "21:00"),
          end: this.setEventDateAndTime("friday", "22:00"),
          color: "purple",
        },
        {
          name: "Chuck Missler",
          start: this.setEventDateAndTime("monday", "23:00"),
          end: this.setEventDateAndTime("monday", "23:30"),
          color: "teal",
        },
        {
          name: "Chuck Missler",
          start: this.setEventDateAndTime("tuesday", "23:00"),
          end: this.setEventDateAndTime("tuesday", "23:30"),
          color: "teal",
        },
        {
          name: "Chuck Missler",
          start: this.setEventDateAndTime("wednesday", "23:00"),
          end: this.setEventDateAndTime("wednesday", "23:30"),
          color: "teal",
        },
        {
          name: "Chuck Missler",
          start: this.setEventDateAndTime("thursday", "23:00"),
          end: this.setEventDateAndTime("thursday", "23:30"),
          color: "teal",
        },
        {
          name: "Chuck Missler",
          start: this.setEventDateAndTime("friday", "23:00"),
          end: this.setEventDateAndTime("friday", "23:30"),
          color: "teal",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("monday", "04:30"),
          end: this.setEventDateAndTime("monday", "05:30"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("monday", "19:00"),
          end: this.setEventDateAndTime("monday", "20:00"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("tuesday", "04:30"),
          end: this.setEventDateAndTime("tuesday", "05:30"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("tuesday", "19:00"),
          end: this.setEventDateAndTime("tuesday", "20:00"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("wednesday", "04:30"),
          end: this.setEventDateAndTime("wednesday", "05:30"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("wednesday", "19:00"),
          end: this.setEventDateAndTime("wednesday", "20:00"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("thursday", "04:30"),
          end: this.setEventDateAndTime("thursday", "05:30"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("thursday", "19:00"),
          end: this.setEventDateAndTime("thursday", "20:00"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("friday", "04:30"),
          end: this.setEventDateAndTime("friday", "05:30"),
          color: "#CD5C5C",
        },
        {
          name: "Curtis Dougherty",
          start: this.setEventDateAndTime("friday", "19:00"),
          end: this.setEventDateAndTime("friday", "20:00"),
          color: "#CD5C5C",
        },
        {
          name: "Ern Baxter",
          start: this.setEventDateAndTime("monday", "00:30"),
          end: this.setEventDateAndTime("monday", "01:30"),
          color: "blue-grey",
        },
        {
          name: "Ern Baxter",
          start: this.setEventDateAndTime("tuesday", "00:30"),
          end: this.setEventDateAndTime("tuesday", "01:30"),
          color: "blue-grey",
        },
        {
          name: "Ern Baxter",
          start: this.setEventDateAndTime("wednesday", "00:30"),
          end: this.setEventDateAndTime("wednesday", "01:30"),
          color: "blue-grey",
        },
        {
          name: "Ern Baxter",
          start: this.setEventDateAndTime("thursday", "00:30"),
          end: this.setEventDateAndTime("thursday", "01:30"),
          color: "blue-grey",
        },
        {
          name: "Ern Baxter",
          start: this.setEventDateAndTime("friday", "00:30"),
          end: this.setEventDateAndTime("friday", "01:30"),
          color: "blue-grey",
        },
        {
          name: "Steve Schell",
          start: this.setEventDateAndTime("monday", "14:00"),
          end: this.setEventDateAndTime("monday", "15:00"),
          color: "#663399",
        },
        {
          name: "Steve Schell",
          start: this.setEventDateAndTime("tuesday", "14:00"),
          end: this.setEventDateAndTime("tuesday", "15:00"),
          color: "#663399",
        },
        {
          name: "Steve Schell",
          start: this.setEventDateAndTime("wednesday", "14:00"),
          end: this.setEventDateAndTime("wednesday", "15:00"),
          color: "#663399",
        },
        {
          name: "Steve Schell",
          start: this.setEventDateAndTime("thursday", "14:00"),
          end: this.setEventDateAndTime("thursday", "15:00"),
          color: "#663399",
        },
        {
          name: "Steve Schell",
          start: this.setEventDateAndTime("friday", "14:00"),
          end: this.setEventDateAndTime("friday", "15:00"),
          color: "#663399",
        },
        {
          name: "Jamie Buckingham",
          start: this.setEventDateAndTime("monday", "09:00"),
          end: this.setEventDateAndTime("monday", "10:00"),
          color: "cyan",
        },
        {
          name: "Jamie Buckingham",
          start: this.setEventDateAndTime("tuesday", "09:00"),
          end: this.setEventDateAndTime("tuesday", "10:00"),
          color: "cyan",
        },
        {
          name: "Jamie Buckingham",
          start: this.setEventDateAndTime("wednesday", "09:00"),
          end: this.setEventDateAndTime("wednesday", "10:00"),
          color: "cyan",
        },
        {
          name: "Jamie Buckingham",
          start: this.setEventDateAndTime("thursday", "09:00"),
          end: this.setEventDateAndTime("thursday", "10:00"),
          color: "cyan",
        },
        {
          name: "Jamie Buckingham",
          start: this.setEventDateAndTime("friday", "09:00"),
          end: this.setEventDateAndTime("friday", "10:00"),
          color: "cyan",
        },
        {
          name: "Dudley Hall",
          start: this.setEventDateAndTime("monday", "16:00"),
          end: this.setEventDateAndTime("monday", "17:00"),
          color: "#2E8B57",
        },
        {
          name: "Dudley Hall",
          start: this.setEventDateAndTime("tuesday", "16:00"),
          end: this.setEventDateAndTime("tuesday", "17:00"),
          color: "#2E8B57",
        },
        {
          name: "Dudley Hall",
          start: this.setEventDateAndTime("wednesday", "16:00"),
          end: this.setEventDateAndTime("wednesday", "17:00"),
          color: "#2E8B57",
        },
        {
          name: "Dudley Hall",
          start: this.setEventDateAndTime("thursday", "16:00"),
          end: this.setEventDateAndTime("thursday", "17:00"),
          color: "#2E8B57",
        },
        {
          name: "Dudley Hall",
          start: this.setEventDateAndTime("friday", "16:00"),
          end: this.setEventDateAndTime("friday", "17:00"),
          color: "#2E8B57",
        },
        {
          name: "David Wilkerson",
          start: this.setEventDateAndTime("monday", "02:00"),
          end: this.setEventDateAndTime("monday", "03:00"),
          color: "#9932CC",
        },
        {
          name: "David Wilkerson",
          start: this.setEventDateAndTime("tuesday", "02:00"),
          end: this.setEventDateAndTime("tuesday", "03:00"),
          color: "#9932CC",
        },
        {
          name: "David Wilkerson",
          start: this.setEventDateAndTime("wednesday", "02:00"),
          end: this.setEventDateAndTime("wednesday", "03:00"),
          color: "#9932CC",
        },
        {
          name: "David Wilkerson",
          start: this.setEventDateAndTime("thursday", "02:00"),
          end: this.setEventDateAndTime("thursday", "03:00"),
          color: "#9932CC",
        },
        {
          name: "David Wilkerson",
          start: this.setEventDateAndTime("friday", "02:00"),
          end: this.setEventDateAndTime("friday", "03:00"),
          color: "#9932CC",
        },
        {
          name: "Dennis Jernigan",
          start: this.setEventDateAndTime("monday", "11:30"),
          end: this.setEventDateAndTime("monday", "12:00"),
          color: "#b75656",
        },
        {
          name: "Heal Ministry Podcast",
          start: this.setEventDateAndTime("tuesday", "11:30"),
          end: this.setEventDateAndTime("tuesday", "12:00"),
          color: "#e642f5",
        },
        {
          name: "Dudley Anderson",
          start: this.setEventDateAndTime("wednesday", "11:30"),
          end: this.setEventDateAndTime("wednesday", "12:00"),
          color: "#0000CD",
        },
        {
          name: "Heal Ministry Podcast",
          start: this.setEventDateAndTime("thursday", "11:30"),
          end: this.setEventDateAndTime("thursday", "12:00"),
          color: "#e642f5",
        },
        {
          name: "Dudley Anderson",
          start: this.setEventDateAndTime("friday", "11:30"),
          end: this.setEventDateAndTime("friday", "12:00"),
          color: "#0000CD",
        },
      ];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      // eslint-disable-next-line prefer-template
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    calendarType() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "day";
      }
      return "custom-daily";
    },
    dailyStartDay() {
      const d = new Date();
      const today = d.getDay();
      if (today >= 1 && today <= 5 && this.$vuetify.breakpoint.smAndDown) {
        const timeStamp = d.setDate(d.getDate()) - 28800000;
        const dateWithTimeZone = new Date(timeStamp).toISOString();
        return dateWithTimeZone.substring(0, 10);
      }
      return this.getPreviousMonday();
    },
    dailyEndDay() {
      return this.getPreviousMonday("friday");
    },
  },
  methods: {
    getPreviousMonday(dayOfWeek) {
      let offset = 0;
      if (dayOfWeek === "friday") {
        offset = 4;
      } else if (dayOfWeek === "thursday") {
        offset = 3;
      } else if (dayOfWeek === "wednesday") {
        offset = 2;
      } else if (dayOfWeek === "tuesday") {
        offset = 1;
      }
      const prevMonday = new Date();
      const timeStamp = prevMonday.setDate(
        prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7) + offset
      );
      const dateWithTimeZone = new Date(timeStamp - 28800000).toISOString();
      const friendlyDate = dateWithTimeZone.substring(0, 10);
      return friendlyDate;
    },
    setEventDateAndTime(dayName, time) {
      const prevDate = this.getPreviousMonday(dayName);
      if (!time) {
        return prevDate;
      }
      return `${prevDate} ${time}`;
    },
    getEventColor(event) {
      return event.color;
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
  },
  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
};
</script>

<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

::v-deep .v-calendar-daily_head-day-label {
  display: none !important;
}

::v-deep .v-calendar-daily_head-weekday {
  padding-bottom: 2em;
  font-weight: bold;
}
</style>
